.footer {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  color: var(--text);
  padding: 2rem 0 1rem 0;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.footer .container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer .col {
  flex: 1;
  max-width: 300px;
}

.footer h1 {
  font-size: 1.2rem;
  margin-bottom: 0rem;
  color: var(--text);
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer li {
  margin-bottom: 0rem;
}

.footer .link {
  background: none;
  border: none;
  color: var(--text-light);
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 0;
}

.footer .link:hover {
  color: var(--primary);
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons li {
  cursor: pointer;
  transition: opacity 0.3s ease;
  color: var(--text-light);
}

.social-icons li:hover {
  color: var(--primary);
  opacity: 1;
}

.footer-bottom {
  text-align: center;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.footer-bottom p {
  color: var(--text-light);
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .footer .container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
  }

  .footer .col {
    width: 100%;
  }

  .social-icons {
    justify-content: center;
  }
}