.about-box {
    /* background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px; */
    padding: 20px;
    margin: 20px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
    width: 100%;
    max-width: 400px; 
}

.number {
    background-color: rgb(150, 200, 244);
    color: #fff; 
    border-radius: 50%; 
    width: 40px;
    height: 40px;
    line-height: 40px; 
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.title_about {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.description {
    font-size: 16px;
    line-height: 1.5;
}
@media screen and (max-width: 768px) {
    .about-box {
        flex-direction: column; 
        align-items: center;
    }

    

    
}