.comparison-section {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.comparison-header {
  text-align: center;
  margin-bottom: 4rem;
}

.comparison-header h2 {
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 1rem;
}

.comparison-header p {
  color: var(--text-light);
  font-size: 1.1rem;
}

.comparison-tables {
  display: grid;
  gap: 2rem;
}

.comparison-table {
  background: rgba(18, 18, 18, 0.95);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.comparison-table h3 {
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.table-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.before-column, .after-column {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.before-column h4, .after-column h4 {
  color: var(--text-light);
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.before-column ul, .after-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.before-column li, .after-column li {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-light);
  margin-bottom: 1rem;
  font-size: 0.95rem;
}

.before-column li:last-child, .after-column li:last-child {
  margin-bottom: 0;
}

.icon-x {
  color: #ff4d4d;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.icon-check {
  color: #4CAF50;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .comparison-section {
    padding: 3rem 1rem;
  }

  .comparison-header h2 {
    font-size: 2rem;
  }

  .table-content {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .before-column, .after-column {
    padding: 1rem;
  }
} 