.case-studies-section {
  padding: 6rem 2rem;
}

.case-studies-container {
  max-width: 1200px;
  margin: 0 auto;
}

.case-studies-header {
  text-align: center;
  margin-bottom: 4rem;
}

.case-studies-header h2 {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 1rem;
}

.case-studies-header p {
  font-size: 1.25rem;
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
}

.case-studies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
}

.case-study-card {
  background: rgba(18, 18, 18, 0.95);
  border-radius: 1rem;
  padding: 2.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.case-study-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 255, 255, 0.1);
}

.case-study-industry {
  color: var(--primary);
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

.case-study-card h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 1rem;
}

.case-study-metrics {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin: 1.5rem 0;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.metric-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.metric-item:last-child {
  margin-bottom: 0;
}

.metric-icon {
  color: var(--primary);
  flex-shrink: 0;
}

.metric-text {
  color: var(--text-light);
  font-size: 1rem;
  line-height: 1.5;
}

.case-study-description {
  color: var(--text-light);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.read-more-btn {
  color: var(--primary);
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.2s;
}

.read-more-btn:hover {
  color: var(--primary-dark);
}

@media (max-width: 768px) {
  .case-studies-section {
    padding: 4rem 1rem;
  }

  .case-studies-header h2 {
    font-size: 2.25rem;
  }

  .case-studies-grid {
    grid-template-columns: 1fr;
  }

  .case-study-card {
    padding: 1.5rem;
  }
}
