.product-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
}

.product-box {
    border: 2px solid #ccc;
    background-color: #f0f8ff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    max-width: 300px; 
    max-height: 400px; 
    overflow: hidden; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    transition: background-color 1s ease; 
}

.product-box:hover {
    transform: scale(1.05);
    background-color: rgb(150, 200, 244);
}

.product-title {
    font-size: 24px;
    font-weight: bold;
    
}

.product-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.product-description {
    font-size: 16px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .product-container {
        flex-direction: column; 
        align-items: center;
    }

    .product-box {
        max-width: 100%; 
        width: auto; 
        margin: 10px; 
        
    }

    .product-title {
        font-size: 20px;
    }

    .product-description {
        font-size: 14px; 
    }
}
