.privacy-policy-container {
  padding: 6rem 2rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 200px);
  color: var(--text);
  background: var(--background);
}

.privacy-policy-content {
  background: var(--background-alt);
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1;
}

.privacy-policy-title {
  font-size: 2.8rem;
  font-weight: 700;
  color: var(--text);
  margin-bottom: 2.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid var(--background);
  text-align: center;
}

.section-title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary);
  margin: 3rem 0 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--background);
  position: relative;
}

.section-title::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: #b88e56;
}

.subsection-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #555;
  margin: 2rem 0 1.2rem;
  padding-left: 1rem;
  border-left: 4px solid #b88e56;
}

.privacy-policy-content p {
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: var(--text-light);
  font-size: 1.1rem;
}

.privacy-policy-content ul {
  margin: 1.5rem 0 2rem 1rem;
  padding-left: 1.5rem;
  list-style: none;
}

.privacy-policy-content li {
  margin-bottom: 1rem;
  color: var(--text-light);
  font-size: 1.1rem;
  line-height: 1.7;
  position: relative;
  padding-left: 1.5rem;
}

.privacy-policy-content li::before {
  content: "•";
  color: #b88e56;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
}

.back-to-home {
  margin-top: 3rem;
  text-align: center;
}

.home-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #805ad5;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 1.1rem;
  box-shadow: 0 4px 6px rgba(128, 90, 213, 0.2);
}

.home-button:hover {
  background-color: #6b46c1;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(128, 90, 213, 0.25);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 4rem 1rem 2rem;
  }

  .privacy-policy-content {
    padding: 2rem 1.5rem;
  }

  .privacy-policy-title {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }

  .section-title {
    font-size: 1.8rem;
    margin: 2.5rem 0 1.2rem;
  }

  .subsection-title {
    font-size: 1.3rem;
    margin: 1.5rem 0 1rem;
  }

  .privacy-policy-content p,
  .privacy-policy-content li {
    font-size: 1rem;
  }
}

/* Print Styles */
@media print {
  .privacy-policy-container {
    padding: 0;
  }

  .privacy-policy-content {
    box-shadow: none;
  }

  .back-to-home {
    display: none;
  }
}
