/* New professional color scheme using brand colors with dark theme */
:root {
  --primary: #b88e56;
  --primary-dark: #8e6b3d;
  --secondary: #dac297;
  --accent: #F2BE22;
  --text: #ffffff;
  --text-light: #9ca3af;
  --background: rgba(0, 0, 0, 1);
  --background-alt: rgba(0, 0, 0, 0.95);
}



/* Apply base font styles */
body {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background);
  color: var(--text);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Salaryman', sans-serif;
  color: var(--text);
}

.home-container {
  width: 100%;
  overflow: hidden;
}

.hero-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  position: relative;
}

.hero-content {
  max-width: 800px;
  z-index: 1;
}

.hero-content h1 {
  font-size: 3.5rem;
  color: var(--primary);
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.hero-content p {
  font-size: 1.25rem;
  color: var(--text-light);
  margin-bottom: 2rem;
}

.hero-buttons, .cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.cta-button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-align: center;
  min-width: 200px;
}

.cta-button.primary {
  background: var(--primary-dark);
  color: var(--text);
  border: none;
}

.cta-button.secondary {
  background: transparent;
  color: var(--text);
  border: 2px solid var(--primary);
}

.cta-button.primary:hover {
  background: var(--primary);
  transform: translateY(-2px);
}

.cta-button.secondary:hover {
  background: var(--primary);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .hero-buttons, .cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .cta-button {
    width: 100%;
    min-width: unset;
  }
}

/* Proof Section */
.proof-section {
  padding: 4rem 2rem;
}

.stats-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.stat-box {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.stat-number {
  font-size: 2.5rem;
  color: var(--primary);
  font-weight: bold;
  margin-bottom: 1rem;
}

.stat-box p {
  color: var(--text-light);
  font-size: 1rem;
  line-height: 1.5;
}

/* Why Work With Us Section */
.why-work-section {
  padding: 6rem 2rem;
}

.why-work-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 4rem;
}

.why-work-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.why-work-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.why-work-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.08);
}

.why-work-icon {
  margin-bottom: 1.5rem;
}

.why-work-item h3 {
  color: var(--primary);
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.why-work-item p {
  color: var(--text-light);
  font-size: 0.95rem;
  line-height: 1.6;
}

/* CTA Section */
.cta-section {
  padding: 6rem 2rem;
  text-align: center;
  background: rgba(18, 18, 18, 0.95);
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-section h2 {
  font-size: 2.5rem;
  color: var(--primary);
  /* margin-bottom: 1.5rem; */
}

.cta-section p {
  font-size: 1.25rem;
  color: var(--text-light);
  margin-bottom: 2rem;
}

/* Section Spacing */
section {
  margin-bottom: 2rem;
}

#services, #case-studies, #about, #faq {
  padding-top: 4rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .why-work-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }

  .stats-container {
    grid-template-columns: 1fr;
  }

  .why-work-grid {
    grid-template-columns: 1fr;
  }

  .why-work-section, .cta-section {
    padding: 4rem 1rem;
  }

  .cta-section h2 {
    font-size: 2rem;
  }
}
