.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  height: 80px;
  backdrop-filter: blur(10px);
}

.navbar-scrolled {
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 70px;
}

.navbar-container {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 2rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo-image {
  height: 40px;
  width: auto;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-link {
  color: var(--text);
  font-weight: 500;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background: var(--primary);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.nav-link:hover::after,
.nav-link-active::after {
  width: 100%;
}

.navbar-cta {
  background: var(--primary);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  border: none;
  transition: all 0.3s ease;
}

.navbar-cta:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

/* Mobile menu button */
.mobile-menu-button {
  display: none;
  padding: 8px;
  background: none;
  border: none;
  color: var(--text);
  cursor: pointer;
}

/* Hide mobile menu on desktop */
.mobile-menu {
  display: block;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: var(--background);
  padding: 0.5rem;
  transform: translateY(-150%);
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.mobile-menu-open {
  transform: translateY(0);
}

.mobile-nav-link {
  display: block;
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid var(--background-alt);
  color: var(--text);
  transition: all 0.3s ease;
  cursor: pointer;
  background: transparent;
}

.mobile-nav-link:hover {
  background: rgba(184, 142, 86, 0.1);
  color: var(--primary);
  transform: translateX(5px);
}

.mobile-nav-link:last-child {
  border-bottom: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar-container {
    justify-content: space-between;
    padding: 0 1rem;
  }

  .mobile-menu-button {
    display: block;
    order: 1;
  }

  .navbar-logo {
    /* order: 2; */
  }

  .navbar-cta {
    order: 3;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .navbar-links {
    display: none; /* Hide desktop navigation on mobile */
  }

  .logo-text {
    font-size: 1.2rem;
  }

  .logo-image {
    height: 30px;
  }

  /* Mobile Menu */
  .mobile-menu {
    display: block;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: var(--background);
    padding: 0.5rem;
    transform: translateY(-150%);
    transition: all 0.3s ease;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  .mobile-menu-open {
    transform: translateY(0);
  }

  .mobile-nav-link {
    display: block;
    padding: 1rem;
    text-align: center;
    border-bottom: 1px solid var(--background-alt);
    color: var(--text);
    transition: all 0.3s ease;
    cursor: pointer;
    background: transparent;
  }

  .mobile-nav-link:hover {
    background: rgba(184, 142, 86, 0.1);
    color: var(--primary);
    transform: translateX(5px);
  }

  .mobile-nav-link:last-child {
    border-bottom: none;
  }
}