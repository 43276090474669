/* About.css */

:root {
  --primary: #b88e56;
  --primary-dark: #8e6b3d;
  --secondary: #dac297;
  --accent: #f2be22;
  --text: #ffffff;
  --text-light: #9ca3af;
  --background: rgba(0, 0, 0, 1);
  --background-alt: rgba(0, 0, 0, 0.95);
}

.about-section {
  padding: 6rem 2rem;
  min-height: 100vh;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8rem; /* Increased spacing between sections */
}

.section-title {
  font-size: 3rem;
  color: #b88e56;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: 600;
}

/* Vision Section */
.vision-container {
  text-align: center;
}

.vision-text {
  font-size: 1.1rem;
  color: var(--text-light);
  max-width: 800px;
  margin: 0 auto 4rem;
  line-height: 1.8;
  opacity: 0.9;
}

.mission-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  max-width: 1000px;
  margin: 0 auto;
}

.mission-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 3rem;
  border-radius: 1.5rem;
  /* backdrop-filter: blur(10px); */
  transition: transform 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.mission-card h3 {
  color: var(--primary);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.mission-card p {
  color: var(--text-light);
  font-size: 1.1rem;
  line-height: 1.6;
  opacity: 0.9;
}

/* Differentiators Section */
.differentiators-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.differentiator-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 2.5rem 2rem;
  border-radius: 1.5rem;
  /* backdrop-filter: blur(10px); */
  transition: transform 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.differentiator-card h3 {
  color: var(--primary);
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.differentiator-card p {
  color: var(--text-light);
  font-size: 1rem;
  line-height: 1.6;
  opacity: 0.9;
}

/* Team Section */
.team-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  max-width: 1000px;
  margin: 0 auto;
  justify-items: center;
}

.team-card {
  background: rgba(255, 255, 255, 0.05);
  padding: 3rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(10px);
  text-align: center;
  width: 100%;
  max-width: 450px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.team-image-container {
  width: 180px;
  height: 180px;
  margin: 0 auto 2rem;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--primary);
}

.team-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-card h3 {
  font-size: 1.8rem;
  color: var(--text);
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.team-role {
  color: var(--primary);
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0.5rem 0 1rem;
}

.team-description {
  color: var(--text);
  line-height: 1.6;
  font-size: 1.1rem;
  opacity: 0.9;
}

/* Hover Effects */
.mission-card:hover,
.differentiator-card:hover,
.team-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .differentiators-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 4rem 1rem;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .mission-grid,
  .team-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .differentiators-grid {
    grid-template-columns: 1fr;
  }

  .about-container {
    gap: 5rem;
  }
}
