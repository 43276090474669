.faqSection {
  padding: 5rem 2rem;
  background: var(--background);
}

.faqSection h2 {
  text-align: center;
  font-size: 3rem;
  color: #b88e56;
  margin-bottom: 1rem;
  font-weight: 600;
}

.faqSubtitle {
  font-size: 1rem;
  text-align: center;
  color: var(--text-light);
  margin-bottom: 3rem;
}

.faqContainer {
  max-width: 800px;
  margin: 0 auto;
}

.faqItem {
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background: rgba(18, 18, 18, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.faqQuestion {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: var(--text);
}

.faqAnswer {
  padding: 0 1.5rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  color: var(--text-light);
}

.faqAnswer.open {
  padding: 0 1.5rem 1.5rem;
  max-height: 1000px;
  opacity: 1;
}

.faqAnswer p {
  color: var(--text-light);
  line-height: 1.6;
  margin: 0;
}

.faqItem.active {
  background: var(--background-alt);
  border-color: rgba(255, 255, 255, 0.1);
}
