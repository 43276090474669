:root {
  --primary: #b88e56;
  --primary-dark: #8e6b3d;
  --secondary: #dac297;
  --accent: #F2BE22;
  --text: #ffffff;
  --text-light: #9ca3af;
  --background: rgba(0, 0, 0, 1);
  --background-alt: rgba(0, 0, 0, 0.95);
}

.services-section {
  padding: 6rem 1rem;
  background: linear-gradient(to bottom, var(--background), var(--background-alt));
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
}

.services-header {
  text-align: center;
  margin-bottom: 4rem;
}

.services-header h2 {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 1rem;
}

.services-header p {
  font-size: 1.25rem;
  color: var(--text-light);
  max-width: 600px;
  margin: 0 auto;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.service-card {
  background: rgba(18, 18, 18, 0.95);
  border-radius: 1rem;
  padding: 2.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.service-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
  border-color: rgba(255, 255, 255, 0.1);
}

.service-icon {
  background: var(--primary);
  color: white;
  width: 64px;
  height: 64px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.service-card h3 {
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 0.5rem;
}

.service-card p {
  color: var(--text-light);
  line-height: 1.6;
  font-size: 1.1rem;
}

.use-cases {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.75rem;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.use-cases h4 {
  color: var(--text);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.use-cases ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.use-cases li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-light);
  font-size: 1rem;
}

.use-cases li svg {
  color: var(--primary);
  flex-shrink: 0;
}

.service-price {
  margin-top: auto;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.service-price span {
  font-size: 0.875rem;
  color: var(--text-light);
  display: block;
  margin-bottom: 0.25rem;
}

.service-price h4 {
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--primary);
}

.services-cta {
  text-align: center;
  background: rgba(18, 18, 18, 0.95);
  color: white;
  padding: 3rem;
  border-radius: 1rem;
  margin-top: 4rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.services-cta h3 {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--primary);
}

.services-cta p {
  font-size: 1.125rem;
  margin-bottom: 2rem;
  opacity: 0.9;
  color: var(--text-light);
}

.consultation-btn {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 600;
  border: none;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  min-width: 200px;
  cursor: pointer;
}

.consultation-btn.primary {
  background: var(--primary-dark);
  color: white;
  border: none;
}

.consultation-btn.secondary {
  background: transparent;
  color: white;
  border: 2px solid var(--primary);
}

.consultation-btn.primary:hover {
  background: var(--primary);
  transform: translateY(-2px);
}

.consultation-btn.secondary:hover {
  background: var(--primary);
  transform: translateY(-2px);
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .services-header h2 {
    font-size: 2.25rem;
  }
  
  .services-grid {
    grid-template-columns: 1fr;
  }
  
  .services-cta {
    padding: 2rem 1rem;
  }

  .service-card {
    padding: 1.5rem;
  }

  .service-card h3 {
    font-size: 1.5rem;
  }

  .service-card p {
    font-size: 1rem;
  }

  .use-cases {
    padding: 1rem;
  }

  .service-price h4 {
    font-size: 1.5rem;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .consultation-btn {
    width: 100%;
    min-width: unset;
  }
}